import { render, staticRenderFns } from "./add-signs.vue?vue&type=template&id=64925430&scoped=true"
import script from "./add-signs.vue?vue&type=script&lang=js"
export * from "./add-signs.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/components/addstyle.scss?vue&type=style&index=0&id=64925430&prod&lang=scss&scoped=true&external"
import style1 from "@/assets/css/components/btn-bottom.scss?vue&type=style&index=1&id=64925430&prod&lang=scss&scoped=true&external"
import style2 from "./add-signs.vue?vue&type=style&index=2&id=64925430&prod&lang=scss&scoped=true"
import style3 from "./add-signs.vue?vue&type=style&index=3&id=64925430&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64925430",
  null
  
)

export default component.exports