<template>
  <div v-if="isShow">
    <cube-tab-bar
      v-model="tabName"
      class="tabBar-outing-model-app"
      @click="clickHandler"
    >
      <cube-tab
        v-for="(item, index) in tabs"
        :key="index"
        :label="item.label"
        :value="item.name"
      >
        <div>
          <i class="icon-joyo tabBar-icon">
            {{ item.icon }}
          </i>
          <div class="tabBar-text">
            {{ item.label }}
          </div>
        </div>
      </cube-tab>
    </cube-tab-bar>
  </div>
</template>

<script>
import MonitorKeyboard from '@/utils/monitorKeyboard'

export default {
  model: {
    prop: 'isShow',
    event: 'updateShow'
  },
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      docmHeight: document.documentElement.clientHeight, // 默认屏幕高度
      tabName: '',
      tabs: [
        {
          label: '签到',
          icon: '\ue6e9',
          name: 'AddSigns',
          url: 'add-sign'
        },
        {
          label: '记录',
          icon: '\ue6ea',
          name: 'SignManage',
          url: 'sign-manage'
        }
      ],
      monitorKeyboard: null,
    }
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler(newVal) {
        this.tabName = newVal
      }
    }
  },
  mounted() {
    this.getKeyboardState()
  },
  beforeDestroy() {
    this.monitorKeyboard.onEnd()
  },
  methods: {
    clickHandler(name) {
      this.$router.push({ name })
    },
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard();
      this.monitorKeyboard.onStart();
      // 监听虚拟键盘弹出事件
      this.monitorKeyboard.onShow(() => {
        this.$emit('updateShow', false)
      })
      // 监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        this.$emit('updateShow', true)
      })
    }
  }
}

</script>

<style lang="scss">
.tabBar-outing-model-app{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $color-FFF;
  // .tabBar-box{
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }
  .tabBar-icon{
    margin-bottom: 2px;
    color: #999BA3;
    font-size: 22px;
  }
  .tabBar-text{
    color: #999BA3;
    font-size: 11px;
    line-height: 16px;
  }

  .cube-tab_active i{
    color: #00A4FF;
  }
  .cube-tab_active div {
    color: #00A4FF;
  }
}
.blank-space{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: $color-FFF
}

</style>

